import Image from "next/image";
import React from "react";

interface AppProps {
  name?: "default" | "full";
  className?: string;
}

const Loader = ({ name = "default", className }: AppProps) => {
  if (name === "full") {
    return (
      <div
        className={`${className} position inset-0 z-[1000] bg-[rgba(255, 255, 255, 0.2)] flex items-center justify-center`}
        style={{ backdropFilter: "blur(10px)" }}
      >
        <div className="bg-white py-4 px-8 rounded-lg shadow-md flex gap-4 items-center">
          <div className="relative w-[60px] h-[60px] flex items-center justify-center">
            <span className="mt-2">
              <Image src="/images/logo.webp" height={38} width={38} alt="" />
            </span>
            <div className="absolute rounded-full h-full w-full border-[5px] border-green-transparent border-l-green-800 flex items-center justify-center animate-spin"></div>
          </div>
          <span>Loading Document(s)...</span>
        </div>
      </div>
    );
  }

  return (
    <div className={`lds-default ${className}`}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default Loader;
