import { captureException } from '@sentry/nextjs';
import {
  collection,
  collectionGroup,
  getDocs,
  limit,
  query,
  where,
} from 'firebase/firestore';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import { firestore } from '@/lib/firebase';

import { useAuth } from '@/components/auth/AuthContext';
import Loader from '@/components/loaders';

import Button from '../buttons/Button';

type props = {
  className?: string;
  selectedUserRole: string;
  setShowBack: Dispatch<SetStateAction<boolean>>;
  setVerificationStatus: Dispatch<SetStateAction<'success' | 'none' | 'fail'>>;
};

const teamMemberExists = async (email: string) => {
  try {
    const teamMembersQuery = query(
      collectionGroup(firestore, 'teamMembers'),
      where('email', '==', email.trim())
    );
    const querySnapshot = await getDocs(teamMembersQuery);

    return querySnapshot.docs.length > 0 ? querySnapshot.docs[0].data() : false;
  } catch (error) {
    // console.log(error);
    captureException(error);
    // show an error message
    toast.error('Could not get team members');
  }
};

export default function EmailField({
  className,
  selectedUserRole,
  setVerificationStatus,
  setShowBack,
}: props) {
  const { sendEmailLink } = useAuth();

  const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');

  const getUserRoles = async () => {
    const userRolesQuery = query(collection(firestore, 'userRoles'), limit(1));
    const data = (await getDocs(userRolesQuery)).docs[0].data();
    return data;
  };

  function ValidateEmail(email: string) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      setIsEmailValid(true);
      setEmail(email);
      return;
    }
    setIsEmailValid(false);
  }

  async function onEmailSubmit() {
    try {
      setIsSubmitting(true);

      // Check if user is in the user roles list and login using firebase login if true
      const userRoles = await getUserRoles();

      if ((userRoles?.LP as string[]).includes(email)) {
        setVerificationStatus('success');
        await processLogin(email);
      } else {
        // check if user is a team member.
        const exists = await teamMemberExists(email);
        if (exists) {
          setVerificationStatus('success');
          await processLogin(email);
        } else {
          setVerificationStatus('fail');
        }
      }
    } catch (error) {
      captureException(error);
      setVerificationStatus('fail');
    } finally {
      setIsSubmitting(false);
    }
  }

  const processLogin = async (email: string) => {
    try {
      const data = await sendEmailLink(email);

      if (!data.success) {
        setVerificationStatus('none');
        setShowBack(true);
        toast.error(<p data-cy='email-error'>{data.message}</p>);
      }
    } catch (error) {
      captureException(error);
      setVerificationStatus('fail');
    }
  };

  useEffect(() => {
    setVerificationStatus('none');
    setIsSubmitting(false);
    setIsEmailValid(false);
  }, [selectedUserRole, setVerificationStatus]);

  return (
    <div id='email-form' className={className}>
      <div className='flex flex-col items-center'>
        <label htmlFor='email' className='mx-16 mb-6 flex text-center'>
          Please enter the email you use to communicate with the NZVC team{' '}
        </label>
        <input
          name='email'
          onChange={(e) => {
            ValidateEmail(e.target.value);
          }}
          type='email'
          className={`bg-light-gray rounded-lg border-2 px-5 py-2 ${
            isSubmitting && 'hidden'
          }`}
          placeholder='Enter your email address'
        />
        {isSubmitting ? (
          <Loader />
        ) : (
          <Button
            data-cy='email-continue'
            onClick={() => {
              isEmailValid && onEmailSubmit();
            }}
            className={`bg-green  mt-5 w-min rounded-lg px-8 py-2 font-bold text-white transition-all
              ${isEmailValid ? 'bg-green' : 'bg-gray'}`}
          >
            Continue
          </Button>
        )}
      </div>
    </div>
  );
}
