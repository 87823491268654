import { Modal } from 'flowbite-react';
import React, { Dispatch, SetStateAction } from 'react';

import Button from '../buttons/Button';

interface IDeleteModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  message: string;
  subMessage?: string;
}

const Error = ({
  message,
  subMessage,
  isOpen,
  setIsOpen,
}: IDeleteModalProps) => {
  return (
    <>
      <React.Fragment>
        <Modal
          show={isOpen}
          size='md'
          popup={true}
          onClose={() => setIsOpen(false)}
        >
          <Modal.Header />
          <Modal.Body>
            <div className='px-0 pb-0 '>
              <div className='flex items-center justify-center gap-4'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-20 w-20 animate-bounce'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='#ea5152'
                  strokeWidth='1'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z'
                  />
                </svg>
              </div>

              <h3 className='text-center text-xl font-bold text-gray-900 dark:text-white'>
                {message}
              </h3>
              <p className='mt-4 text-center dark:text-white'>{subMessage}</p>

              <div className='mt-10 flex w-full justify-center gap-5'>
                <Button
                  data-cy='proceed-delete'
                  onClick={() => {
                    setIsOpen(false);
                  }}
                  className='bg-red-500'
                >
                  OK
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    </>
  );
};

export default Error;
