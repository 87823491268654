import dashify from 'dashify';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import useHelpAndAdvice from '@/hooks/useHelpAndAdvice';

import { useAuth } from '@/components/auth/AuthContext';
import EmailField from '@/components/auth/EmailField';
import Button from '@/components/buttons/Button';
import Loader from '@/components/loaders';
import ErrorModal from '@/components/modals/ErrorModal';

import ArrowLeft from '~/svg/arrow-left.svg';
import Google from '~/svg/google.svg';
import Help from '~/svg/help.svg';

type UserRole = 'Limited Partner' | 'General Partner' | 'none';

const userRoles = ['Limited Partner', 'General Partner'];

export default function Login() {
  const router = useRouter();
  const { authUser, authLoading, signInWithGoogle } = useAuth();
  const { setShowHelpAndAdvice } = useHelpAndAdvice();

  const [showUserRoleChoice, setShowUserRoleChoice] = useState<boolean>(true);
  const [selectedUserRole, setSelectedUserRole] = useState<UserRole>('none');

  const [errorModal, setErrorModal] = useState(false);

  const [lpVerificationStatus, setLpVerificationStatus] = useState<
    'success' | 'fail' | 'none'
  >('none');

  const [showBack, setShowBack] = useState<boolean>(false);

  useEffect(() => {
    if (!authLoading && authUser) router.push('/dashboard');
  }, [authLoading, authUser, router]);

  useEffect(() => {
    if (lpVerificationStatus == 'success') setShowBack(false);
  }, [lpVerificationStatus]);

  const handleGoogleSignIn = async () => {
    const result = await signInWithGoogle();
    if (!result.success) {
      setSelectedUserRole('none');
      setShowBack(false);
      setShowUserRoleChoice(true);
      setErrorModal(true);
    } else {
      router.push('/dashboard');
    }
  };

  return (
    <div className='Login' id='Login'>
      <main
        data-cy='login-page'
        className='bg-light-gray relative h-screen dark:bg-gray-700'
      >
        <div id='header' className='bg-white py-4 dark:bg-gray-700'>
          <span id='logo' className='my-auto flex justify-end pr-4'>
            <Image
              src='/images/dark-logo.webp'
              width={40}
              height={40}
              alt='NZVC logo'
            />
          </span>
        </div>

        <div
          id='login-modal'
          className='absolute top-1/2 left-1/2 flex min-h-[25vh] min-w-[40vw] -translate-x-1/2 -translate-y-1/2 transform flex-col items-center justify-center rounded-md bg-white py-10 px-10 shadow-md dark:bg-gray-600 dark:text-white'
        >
          {showBack && (
            <div
              onClick={() => {
                setShowUserRoleChoice(true);
                setSelectedUserRole('none');
                setShowBack(false);
              }}
            >
              <ArrowLeft className='absolute top-0 left-0 m-3' />
            </div>
          )}

          <h1
            className={`mt-3 text-lg font-bold ${
              lpVerificationStatus == 'fail' && 'hidden'
            }`}
          >
            Welcome to the NZVC Dashboard
          </h1>

          <div
            className={`${lpVerificationStatus != 'none' && 'hidden'} block`}
          >
            <h1 className='mt-2 text-center'>
              {!showUserRoleChoice && selectedUserRole != 'none'
                ? selectedUserRole != 'General Partner'
                  ? ''
                  : selectedUserRole
                : 'Please Login as:'}
            </h1>

            {!showUserRoleChoice && selectedUserRole == 'Limited Partner' && (
              <EmailField
                setVerificationStatus={setLpVerificationStatus}
                selectedUserRole={selectedUserRole}
                setShowBack={setShowBack}
                className={` transition-all ${
                  !showUserRoleChoice && selectedUserRole == 'Limited Partner'
                    ? 'pointer-events-auto mt-5 opacity-100'
                    : 'pointer-events-none -mt-5 scale-95 opacity-0'
                }`}
              />
            )}

            {/* Log in as General Partner */}
            {!showUserRoleChoice && selectedUserRole == 'General Partner' && (
              <div className='mt-6 flex justify-center'>
                {authLoading ? (
                  <Loader />
                ) : (
                  <Button
                    data-cy='google-button'
                    variant='primary'
                    onClick={handleGoogleSignIn}
                  >
                    <Google className='mr-2 h-5 w-5' />
                    <span>Sign in with Google</span>
                  </Button>
                )}
              </div>
            )}

            {/* Show UserRole Choice */}
            <div
              className={` mt-6 flex justify-between gap-4 ${
                showUserRoleChoice
                  ? 'pointer-events-auto opacity-100'
                  : 'pointer-events-none absolute translate-y-5 scale-95 opacity-0'
              }`}
            >
              {userRoles.map((userRole: string, index: number) => (
                <div key={index}>
                  <Button
                    data-cy={dashify(userRole)}
                    onClick={() => {
                      setSelectedUserRole(userRole as UserRole);
                    }}
                    variant={selectedUserRole != userRole ? 'light' : 'primary'}
                  >
                    {userRole}
                  </Button>
                </div>
              ))}
            </div>
          </div>

          {lpVerificationStatus == 'success' && (
            <div data-cy='login-message' className='mt-5'>
              Please check your email for the login link
            </div>
          )}

          {lpVerificationStatus == 'fail' && (
            <div data-cy='login-message' className='mt-5 text-center'>
              <div className='text-xl font-bold'>
                Your email could not be found :/
              </div>
              <div className='text-sm'>
                Please contact <strong>kristina@nzvc.co.nz</strong> to confirm
                you&apos;re using the correct one
              </div>
            </div>
          )}

          {!showBack && lpVerificationStatus == 'none' && (
            <div className='mt-6'>
              <Button
                data-cy='continue'
                onClick={() => {
                  setShowBack(true);
                  setShowUserRoleChoice(false);
                }}
              >
                Continue
              </Button>
            </div>
          )}
        </div>
        <div
          id='help-and-advice'
          onClick={() => setShowHelpAndAdvice(true)}
          className='fixed bottom-12 left-1/2 flex -translate-x-1/2 justify-center gap-2 text-gray-400'
        >
          <Help className='h-6 w-6' />
          Help & Advice
        </div>
      </main>
      {errorModal && (
        <ErrorModal
          data-cy='not-registered-error'
          message='Email is not allowed'
          subMessage='You cannot login as a GP or VP with this email. Contact Kristina at kristina@nzvc.co.nz'
          isOpen={errorModal}
          setIsOpen={setErrorModal}
        />
      )}
    </div>
  );
}
